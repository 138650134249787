import React, {lazy, useEffect, useState ,Suspense } from "react";
import MainLoader from "../constants/MainLoader"
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import {
  AddSpecialOffers,
  ManageSpecialOffers,
  EditSpecialOffer,
} from "../View/Merchant/Offers"; 
import { MerchantAddTourModule} from "../View/Merchant/TourModule"
  import {
    AddAvailability,
    CheckAvailability,
    EditAvailability,
    CancelAvailability,
  } from "../View/Merchant/ActivityAvailability"; 
  import {
    VeiwBookings,
    BookingDetailedView,
    OrderInvoice,
  } from "../View/Merchant/Bookings"; 

  // const Layout = lazy(() => import("../Layout")); 
  import Layout from "../Layout"
// Components
const SuccessView = lazy(() => import("../Component/SuccessPage/SuccessView"));
const ComingSoon = lazy(() => import("../Component/ComingSoon"));
const Login = lazy(() => import("../Athentication/Login"));
const ProfileView = lazy(() => import("../View/Merchant/ProfileDetails/ViewProfile"));
const Dashboard = lazy(() => import("../View/Merchant/Dashboard"));
const AddProduct = lazy(() => import("../View/Merchant/Activity/AddProduct"));
const ManageProducts = lazy(() => import("../View/Merchant/Activity/ManageProducts"));
const AddCompanyDetails = lazy(() => import("../View/Merchant/Company/AddCompanyDetails"));
const Facts = lazy(() => import("../View/Merchant/Help/Facts"));
const ContactForm = lazy(() => import("../View/Merchant/Help/ContactForm"));
const AccountManagement = lazy(() => import("../View/Merchant/Company/AccountManagement"));
const AddProfile = lazy(() => import("../View/Merchant/Company/AddProfile"));
const EditProduct = lazy(() => import("../View/Merchant/Activity/EditProduct"));
const ManageAvailability = lazy(() => import("../View/Merchant/ActivityAvailability/ManageAvailability"));

const ManageDraft = lazy(() => import("../View/Merchant/Activity/ManageDraft"));
const EditDraftProduct = lazy(() => import("../View/Merchant/Activity/EditDraftProduct"));
const ChangePassword = lazy(() => import("../View/Merchant/ChangePassword/ChangePassword"));
const Registration = lazy(() => import("../Athentication/Registration"));
const ReviewHome = lazy(() => import("../View/Merchant/ReviewAndRating/ReviewHome"));
const ViewReviewAndRating = lazy(() => import("../View/Merchant/ReviewAndRating/ViewReviewAndRating"));
const ActivityAssignByAdmin = lazy(() => import("../View/Merchant/Activity/ActivityAssignByAdmin"));
const USerAccountDetails = lazy(() => import("../View/Merchant/Company/USerAccountDetails"));
const BookingForPayout = lazy(() => import("../View/Merchant/Finance/BookingForPayout"));
const Invoices = lazy(() => import("../View/Merchant/Finance/Invoices"));
const PaymentDetails = lazy(() => import("../View/Merchant/Finance/PaymentDetails"));
const PaymentSetting = lazy(() => import("../View/Merchant/Finance/PaymentSetting"));
const PendingProduct = lazy(() => import("../View/Merchant/Activity/PendingProduct"));
const RejectProduct = lazy(() => import("../View/Merchant/Activity/RejectProduct"));
const MonthltyAvailability = lazy(() => import("../View/Merchant/ActivityAvailability/MonthltyAvailability"));
const ManageTourModule = lazy(() => import("../View/Merchant/TourModule/ManageTourModule"));
const ManageAdditionalStuff  = lazy(() => import("../View/Merchant/AdditionalStuff/ManageAdditionalStuff"));



function AppRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  let from = location.pathname || "/";
  const [loggedIn, setloggedIn] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("Authorization") &&
      localStorage.getItem("userType")
    ) {
      if (from === "/") {
        navigate("/");
      } else {
        navigate(from, { replace: true });
      }
      setloggedIn(true);
    } else {
      if (from === "/registration") {
        navigate("/registration");
      } else {
        navigate("/login");
      }
    }
  }, [loggedIn,from,navigate]);

  return (
 

    <Routes>
    <Route path="/success-view" element={<Suspense fallback={<MainLoader isLoading={true} />}><SuccessView /></Suspense>} />
    <Route path="/login" element={<Suspense fallback={<MainLoader isLoading={true} />}><Login /></Suspense>} />
    <Route path="/registration" element={<Suspense fallback={<MainLoader isLoading={true} />}><Registration /></Suspense>} />
    <Route element={<Layout />}>
      <Route path="/profile-details" element={<Suspense fallback={<MainLoader isLoading={true} />}><ProfileView /></Suspense>} />
      <Route path="/" element={<Suspense fallback={<MainLoader isLoading={true} />}><Dashboard /></Suspense>} />
      <Route path="/add-company-details" element={<Suspense fallback={<MainLoader isLoading={true} />}><AddCompanyDetails /></Suspense>} />
      <Route path="/manage-products/:product_type?" element={<Suspense fallback={<MainLoader isLoading={true} />}><ManageProducts /></Suspense>} />
      <Route path="/pending-product" element={<Suspense fallback={<MainLoader isLoading={true} />}><PendingProduct /></Suspense>} />
      <Route path="/reject-product" element={<Suspense fallback={<MainLoader isLoading={true} />}><RejectProduct /></Suspense>} />
      <Route path="/manage-drafts" element={<Suspense fallback={<MainLoader isLoading={true} />}><ManageDraft /></Suspense>} />
      <Route path="/edit-draft-product/:id" element={<Suspense fallback={<MainLoader isLoading={true} />}><EditDraftProduct /></Suspense>} />
      <Route path="/create-products" element={<Suspense fallback={<MainLoader isLoading={true} />}><AddProduct /></Suspense>} />
      {/* <Route path="/product-special-offer" element={<Suspense fallback={<MainLoader isLoading={true} />}><AdminManageActivity /></Suspense>} /> */}
      <Route path="/add-special-offer" element={<Suspense fallback={<MainLoader isLoading={true} />}><AddSpecialOffers /></Suspense>} />
      <Route path="/manage-special-offers/:offer_type?" element={<Suspense fallback={<MainLoader isLoading={true} />}><ManageSpecialOffers /></Suspense>} /> 
      <Route path="/additional-stuff" element={<Suspense fallback={<MainLoader isLoading={true} />}><ManageAdditionalStuff /></Suspense>} />
      <Route path="/add-tour-module" element={<Suspense fallback={<MainLoader isLoading={true} />}><MerchantAddTourModule /></Suspense>} />
      <Route path="/manage-tour-module" element={<Suspense fallback={<MainLoader isLoading={true} />}><ManageTourModule /></Suspense>} />
      <Route path='/edit-tour-module/:id' element={<Suspense fallback={<MainLoader isLoading={true} />}><MerchantAddTourModule /></Suspense>} />
      <Route path="/edit-special-offer/:id" element={<Suspense fallback={<MainLoader isLoading={true} />}><EditSpecialOffer /></Suspense>} />
      <Route path="/frequently-asked-questions" element={<Suspense fallback={<MainLoader isLoading={true} />}><Facts /></Suspense>} />
      <Route path="/contact-form" element={<Suspense fallback={<MainLoader isLoading={true} />}><ContactForm /></Suspense>} />
      <Route path="/account-management" element={<Suspense fallback={<MainLoader isLoading={true} />}><AccountManagement /></Suspense>} />
      <Route path="/create-profile" element={<Suspense fallback={<MainLoader isLoading={true} />}><AddProfile /></Suspense>} />
      <Route path="/edit-product/:id" element={<Suspense fallback={<MainLoader isLoading={true} />}><EditProduct /></Suspense>} />
      <Route path="/ManageAvailability/:id" element={<Suspense fallback={<MainLoader isLoading={true} />}><ManageAvailability /></Suspense>} />
      <Route path="/MonthltyAvailability/:id" element={<Suspense fallback={<MainLoader isLoading={true} />}><MonthltyAvailability /></Suspense>} />
      <Route path="/check-product-availability" element={<Suspense fallback={<MainLoader isLoading={true} />}><CheckAvailability /></Suspense>} />
      <Route path="/add-product-availability/:id" element={<Suspense fallback={<MainLoader isLoading={true} />}><AddAvailability /></Suspense>} />
      <Route path="/edit-product-availability/:acti_id/:slot_id" element={<Suspense fallback={<MainLoader isLoading={true} />}><EditAvailability /></Suspense>} />
      <Route path="/cancel-product-availability" element={<Suspense fallback={<MainLoader isLoading={true} />}><CancelAvailability /></Suspense>} />
      <Route path="/activityassignbyadmin" element={<Suspense fallback={<MainLoader isLoading={true} />}><ActivityAssignByAdmin /></Suspense>} />
      <Route path="/reviewHome" element={<Suspense fallback={<MainLoader isLoading={true} />}><ReviewHome /></Suspense>} />
      <Route path="/viewReviewAndRating/:id" element={<Suspense fallback={<MainLoader isLoading={true} />}><ViewReviewAndRating /></Suspense>} />
      <Route path="/userAccount-Details" element={<Suspense fallback={<MainLoader isLoading={true} />}><USerAccountDetails /></Suspense>} />
      <Route path="/coming-soon" element={<Suspense fallback={<MainLoader isLoading={true} />}><ComingSoon /></Suspense>} />
      <Route path="/change-password" element={<Suspense fallback={<MainLoader isLoading={true} />}><ChangePassword /></Suspense>} />
      <Route path="/view-bookings" element={<Suspense fallback={<MainLoader isLoading={true} />}><VeiwBookings /></Suspense>} />
      <Route path="/view-bookingInvoice" element={<Suspense fallback={<MainLoader isLoading={true} />}><VeiwBookings /></Suspense>} />
      <Route path="/booking-details/:id" element={<Suspense fallback={<MainLoader isLoading={true} />}><BookingDetailedView /></Suspense>} />
      <Route path="/order-invoice" element={<Suspense fallback={<MainLoader isLoading={true} />}><OrderInvoice /></Suspense>} />
      {/* <Route path="/finance" element={<Suspense fallback={<MainLoader isLoading={true} />}><Finance /></Suspense>} /> */}
      <Route path="/booking-payout" element={<Suspense fallback={<MainLoader isLoading={true} />}><BookingForPayout /></Suspense>} />
      <Route path="/invoice" element={<Suspense fallback={<MainLoader isLoading={true} />}><Invoices /></Suspense>} />
      <Route path="/payment-details" element={<Suspense fallback={<MainLoader isLoading={true} />}><PaymentDetails /></Suspense>} />
      <Route path="/payment-settings" element={<Suspense fallback={<MainLoader isLoading={true} />}><PaymentSetting /></Suspense>} />
    </Route>
  </Routes>
  );
}

export default AppRoutes;
