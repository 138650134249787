import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../services/api/apiHelpers";

let initialState = {
  status: "idle",
  merchant: {},
  merchant_details: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const merchantSignup = createAsyncThunk("merchant_signup", async (data) => {
  let response = await ApiHelperFunction({
    urlPath: "/admin/register",
    method: "POST",
    data: JSON.stringify(data),
  });

  if (response.status === 200) {
    localStorage.setItem("user_email", response.data.data.email);
    localStorage.setItem("Authorization", response.data.data.token);
    localStorage.setItem("userType", "Merchant");
    toast.success("Registered successfully!");
    return response.data;
  } else {
    toast.error("Something went wrong.");

    return data.rejectedWithValue();
  }
});

export const merchantSignin = createAsyncThunk("merchant_login", async (data) => {
  try{
  let response = await ApiHelperFunction({
    urlPath: "/admin/login",
    method: "POST",
    data: JSON.stringify(data),
  });

  if (response.status === 200) {
    localStorage.setItem("Authorization", response.data.data.token);
    localStorage.setItem("userType", "Merchant");
    toast.success("Login successful!");
    return response.data;
  } else {
    toast.error("Wrong email or password");

    return data.rejectedWithValue();
  }
}catch(error){
}
});

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(merchantSignup.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(merchantSignup.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.merchant = payload.data;
      })
      .addCase(merchantSignup.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(merchantSignin.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(merchantSignin.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.merchant_details = payload.data;
      })
      .addCase(merchantSignin.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = AuthSlice.actions;

export default AuthSlice.reducer;
