import axios from 'axios';
import { toast } from 'react-toastify';

// Constants
import { BASE_URL, API_ENDPOINT_PATH } from '../../constants/config';

const API_URL = BASE_URL + API_ENDPOINT_PATH;

let token = localStorage.getItem('Authorization');
let user_type = 'Merchant';

export async function requestData(url, method, data = {}) {

try{

  if(!token&&!user_type&&!url)
    throw new Error("Something wrong ")
 
  let apiUrl = API_URL + url;
  var myHeaders = new Headers();
  
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', token);
  myHeaders.append('userType', user_type);
  myHeaders.append('Access-Control-Allow-Origin', BASE_URL);

  var raw = JSON.stringify(data);
  var requestOptions = {};

  if (method === 'POST') {
    requestOptions = {
      method: method,
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
  } else if (method === 'PUT') {
    requestOptions = {
      method: method,
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
  } else {
    requestOptions = {
      method: method,
      headers: myHeaders,
      redirect: 'follow',
    };
  }

  return await fetch(apiUrl, requestOptions)
    .then(response => response.json())
    .then(result => {
      return result;
    })
    .catch((error)=>{
throw new Error(error)

    })


  }catch(error){

  
  }
  

}
export async function fileUpload(url, method, file) {
  try {
    let token = localStorage.getItem('Authorization');
    if (!token) {
      throw new Error("Token Not Found");
    }

    let user_type = localStorage.getItem('userType');
    if (!user_type) {
      throw new Error("userType Not Found");
    }

    if (!url) {
      throw new Error("URL Not Found");
    }

    let apiEndpoint = API_URL + url;
    let headers = {
      'Authorization': token,
      'userType': user_type,
    };

    const response = await fetch(apiEndpoint, {
      method: method,
      body: file,
      redirect: 'follow',
      headers: headers,
    });

    if (!response.ok) {
  
      throw new Error("File Upload Failed");
    }
   
    return await response.json();
  } catch (error) {
    
    console.error("Error in fileUpload:", error.message||"");
    throw error;
  }
}





export const ApiHelperFunction = async props => {
try{



let token = localStorage.getItem('Authorization');
  if(!token)
    throw new Error("Token Not Found")
 const { urlPath, method, data } = props;
 if(!urlPath)
  throw new Error("Url Not Found")

 if(!method)
  throw new Error("Method Not Found")
  let config = {
    method: `${method}`,
    url: `${API_URL}${urlPath}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
      userType: user_type,
    },
    data:data,
  };
  let responseData;

  await axios(config)
    .then(function (res) {
      responseData = res;
    })
    .catch(function (error) {
      if (error?.response?.status === 401) {
        toast.error('Unauthorized');
        responseData = error;
        throw new Error("Authorization Error ")
      } else {
        responseData = error;
        throw new Error("Server Error ")
      }
    });

  return responseData;


}catch(error){
  toast.error("something Wrong Refresh Again ")
}
};


export { API_URL }