import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  flag: "",
};

export const ControlTableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    changeflag: (state, action) => {
      state.flag = action.payload;

    },
  },
});

export const { changeflag } = ControlTableSlice.actions;

export default ControlTableSlice.reducer;
