import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Redux actions
import { getAllBookings } from "../../../redux/merchantSlice/bookingSlice";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Components
import Loader from "../../../Component/Loader";
import Select from "react-select";
import Table from "../../../Component/Table";



function OrderInvoice() {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const [merchant, setmerchant] = useState({ orderId: "", activityTitle: "" });
  const [invoiceData, setinvoiceData] = useState(null);
  const { bookings } = useSelector((state) => state.bookingSlice);
  useEffect(() => {
    dispatch(getAllBookings());
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (merchant.orderId === "") {
      return toast.error("Please select an order.");
    } else {
      try {
        setisLoading(true);
        let res = await JSON_API["getOrderInvoice"]({ id: merchant.orderId });
        if (res.isSuccess) {
          setinvoiceData(res);
        }
        setinvoiceData(res);
        setisLoading(false);
      } catch (error) {
        setisLoading(false);
      }
    }
  };
  const tableData = {
    columns: [
      {
        label: "Invoice",
        field: "link",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      invoiceData?.data?.status
        ? {
            link: (
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left flex2">
                    <div
                      className="widget-heading"
                      style={{ opacity: 1, textAlign: "center" }}
                    >
                      <a
                        href={invoiceData?.data?.data}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Link
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ),
          }
        : "",
    ],
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="">
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Activity Bookings</div>
              <div className="row" style={{ padding: "1rem" }}>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="orderId"
                      className="form-label font-weight-bold"
                    >
                      Orders*
                    </label>
                    <Select
                      name="orderId"
                      id="orderId"
                      classNamePrefix="select"
                      // ref={inputRef}
                      options={bookings?.map((item, idx) => ({
                        value: item?.orderId,
                        label: item?.activity[0]?.activityTitle,
                      }))}
                      value={{
                        value: merchant.orderId,
                        label: merchant.activityTitle,
                      }}
                      // isMulti
                      onChange={(val) => {
                        setmerchant((prev) => {
                          return {
                            ...prev,
                            orderId: val?.value,
                            activityTitle: val?.label,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  className="mt-2 btn btn-primary d-table"
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Table
        striped
        tableTitle={`Order Invoice`}
        data={tableData}
        paging={false}
      />
    </>
  );
}

export default OrderInvoice;
