
import { useState } from 'react'
import { requestData } from '../services/api/apiHelpers'

function useActivitiesMethods() {
    const [tourModules,setTourModules] = useState([])
    const [isLoadings, setIsLoadings] = useState(false)

    const fetchTourModules = async(id)=>{
        try{
        setIsLoadings(true)
        let result = await requestData(`/merchant/view-cities-tourModule/${id}`,"GET")
        if(result && result.status){
            setTourModules(result.data)
            setIsLoadings(false)
        }
    }catch(error){
                }
        
    }
  return {fetchTourModules,tourModules, isLoadings}
}

export default useActivitiesMethods