

function DownloadPdf(urls,METHOS, data,setisLoading) {
    const token = localStorage.getItem("Authorization")

const download_pdf=async()=>{
 
        setisLoading(true)
        try {
          const response = await fetch(urls, {
            method: METHOS,

            headers: {
              'Content-Type': 'application/json',
              "authorization":token,
              "userType":"Merchant"
            },
            body: Object.keys(data).length > 0 ? JSON.stringify(data) : undefined,
          });
          if(response && response.status){
            setisLoading(false)
          }
           // Extract the blob data from the response
           const blob = await response.blob();
    
           // Create a URL for the blob object
           const blobUrl = URL.createObjectURL(blob);
    
           // Create a temporary anchor element
           const a = document.createElement('a');
           a.href = blobUrl;
           
           // Set the download attribute with a filename
           a.download = 'downloaded-file'; // You can specify a file name here
           
           // Append the anchor to the body (necessary for Firefox)
           document.body.appendChild(a);
    
           // Trigger a click event on the anchor
           a.click();
    
           // Remove the anchor from the document
           document.body.removeChild(a);
    
           // Revoke the object URL to free up memory
           URL.revokeObjectURL(blobUrl);
  
          if (!response.ok) {
            throw new Error('Failed to fetch PDF');
          }
    
          const pdfBlob = await response.blob();
          const pdfUrl = URL.createObjectURL(pdfBlob);
    
          const embedElement = document.createElement('embed');
          embedElement.setAttribute('src', pdfUrl);
          embedElement.setAttribute('type', 'application/pdf');
          embedElement.setAttribute('width', '100%');
          embedElement.setAttribute('height', '600px');
    
          const pdfContainer = document.getElementById('pdf-container');
          pdfContainer.appendChild(embedElement);
        
        } catch (error) {
          console.error('Error fetching PDF:', error);
        }
      
}
download_pdf()

}

export default DownloadPdf
