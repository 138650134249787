// Hooks
import React, { useEffect,  } from "react";
import { useNavigate,  } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import logo from "../../assets/utils/images/logo-inverse2.png";

// Helpers
import { toast } from "react-toastify";

// Redux actions
import {
  getProfileDetails,
  clearState,
} from "../../redux/merchantSlice/profileSlice";
import { activityActionsClearState } from "../../redux/adminSlice/activitySlice";
import { openModal,  } from "../../redux/merchantSlice/modalSlice";

// API Functions
import { JSON_API } from "../../services/api/jsonApiCallWithInterceptor";

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  // Redux stores
  const { profile_data, loginStatus } = useSelector(
    (state) => state.profileSlice
  ); 

  const isModalOpen = useSelector((state) => state.modalSlice?.isModalOpen);

  // Function to open the modal
  const hankclickModal = (e) => {
    e.preventDefault();
    dispatch(openModal());
  };



  useEffect(() => {
    getOwnInvoice();
  }, []);

  useEffect(() => {
    if (loginStatus) dispatch(getProfileDetails());
  }, [loginStatus, dispatch]);

  const drawyer = () => {
    $(".app-sidebar").toggleClass("closed-sidebar");
    $(".app-main__outer").toggleClass("test");
    $(".app-footer__inner").toggleClass("test3");
  };
  const drawyerMob = () => {
    $(".app-sidebar").toggleClass("sidebarmob");
    $(".sidebar-mobile-overlay").toggleClass("sidebar-mobile-open");
    $(".BurgerBox").toggleClass("BurgerActive");
  };

  const getOwnInvoice = async () => {
    const dateTimeString = new Date();

    var mm =
      dateTimeString.getMonth() > 8
        ? dateTimeString.getMonth() + 1
        : "0" + (dateTimeString.getMonth() + 1);
    var dd =
      dateTimeString.getDate() > 9
        ? dateTimeString.getDate()
        : "0" + dateTimeString.getDate();
    var yyyy = dateTimeString.getFullYear();

    var reformattedDate = yyyy + "-" + mm + "-" + dd;

    try {
      let res = await JSON_API["getSelfInvoice"]({
        invoiceDate: reformattedDate,
      });

      if (res.isSuccess) {
      } else {
      }
    } catch (error) {
    }
  };



  const logout = () => {
    localStorage.clear();
    dispatch(clearState());
    dispatch(activityActionsClearState());
    navigate("/login");
    toast.success("Log out successfully");
  };

  return (
    <>
      {/* <Sidebar /> */}
      <div className="app-header bg-strong-bliss header-text-light header-shadow appear-done enter-done">
        <div className="app-header__logo">
          <div className="logo-src">
            <img src={logo} className="img-fluid" alt="logo" />
          </div>
          <div className="header__pane ms-auto">
            <div>
              <button
                className="jsx-2200192874 BurgerSlider Burger"
                onClick={drawyer}
              >
                <div className="jsx-2200192874 BurgerBox">
                  <div className="jsx-2200192874 BurgerInner" />
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="app-header__mobile-menu">
          <div>
            <button
              className="jsx-3928250682 BurgerSlider Burger"
              onClick={drawyerMob}
            >
              <div className="jsx-3928250682 BurgerBox">
                <div className="jsx-3928250682 BurgerInner" />
              </div>
            </button>
          </div>
        </div>

        <div className="app-header__content">
       
          <div className="app-header-right">
            <div className="header-btn-lg pe-0">
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    <div className="btn-group">
                      <button
                        style={{
                          display: "flex",
                          justifyContent: "center",

                          alignItems: "center",
                        }}
                        type="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="p-0 btn btn-link"
                        onClick={(e) => hankclickModal(e)}
                      >
                        {profile_data?.logo ? (
                          <img
                            style={{width: "40px", height: "40px"}}
                            className="rounded-circle mt-1"
                            src={profile_data?.logo}
                            alt=""
                          />
                        ) : (
                          <img
                            width={42}
                            className="rounded-circle mt-1"
                            src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwgKCgkLDRYPDQwMDRsUFRAWIB0iIiAdHx8kKDQsJCYxJx8fLT0tMTU3Ojo6Iys/RD84QzQ5OjcBCgoKDQwNGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIALQAvwMBIgACEQEDEQH/xAAbAAEAAgMBAQAAAAAAAAAAAAAABAUCAwYBB//EADYQAQACAQIFAgIHBgcAAAAAAAABAgMEEQUSMUFRISJSYRNxgZGhscEyNEJi8PEVIyQzcpLR/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oADAMBAAIRAxEAPwD6WA0yAAAAAAAABETM7RG8+Ib6aLU36YpiPn6A0CX/AIbqdulf+zTm0+bB/uUmI89YFagBAAAAAAAAAAAAAAAABYaThs3iL596x2rHU4XpYvb6a8e2s+2PMrZLVYYsOPDG2OkV+pmCKPLVi9ZraImJ6xL0BR6vRZMWa0Y6XtTrExG+yL0naXTI+r0lNTXpEXjpZdTFCPb1tS81tG1onaYeKgAAAAAAAAAAAAREzO0dRu0VebV4o/m3+4F5gxxhw0xx/DDYDLQAAAAACp4xjiuWmSP4o2n7Fet+MR/p6T4v+kqhqIACAAAAAAAAAACTw399x/b+UozfoJ5dZin57Cr8BlQAAAAAEHjH7rX/AJx+UqdbcZn/ACccebb/AIKlqJQAQAAAAAAAAAAT+F6amWZy339kxy+vdAWnBbe3LXxMSVVkAyoAAAAACPrNNXU49rb81YnlmPKhdJeeWlrT2jdzaxKAKgAAAAAAAAAAlcOzRh1Mc07VtHLM+EUB0wg8JyTfTzSZ3mk/gnMtAAAAAPJmIjeekAicTzxi09qb++8bRHy7qVllvOTJa9pmZtO/qxaQAEAAAAAAAAAAAATOFZox6jltPpeNvt7LpzK64dq51FJpf9uses+UqxMARQABG4jmjFpbevut7YSJnaN1Dq9TbU5eafSsfsx4WDQArIAAAAAAAAAAAAAAseCx78s+Ij9VctuDV2w5Lebbfd/cqrABlQABzMxtMx4dM53U15NRlr4tKxK1gKgAAAAAAAAAAAAPaVte0VpWbTPaIWGn4Xafdnty/wAteoqFgw3z3imON57z2hfYMVcGKuOvSO/l7jx0xV5cdYrHyZpaoAgAAK7iektefpsUbzt7ojv81iA5kXmp0OLPvbbkv8Ufqq9To82DeZjmp8VWtRHAEAAAAAAAStJosmo2tPtx/FPf6hUWsTaYrWJmZ6RCw0/DL292eeSPhjqsNPpsWnjbHX172nrLcmmNeHDjw12x0iv6tgIoAAAAAAAAACJqOH4c281j6O3mOn3KvUaTLp/W9d6/FHRfnVdHMi31XDaZN7YdqW8dp/8AFVkx3xXmmSs1tHaVRiAIAyx0nJkrSOtp2BM4bo4zT9Llj2RPpHxLjoxx0jHStKxtFY2hky0AAAAAAAAAAAAAAAANOp09NRj5bx69rd4bgHN5cdsOS2O8esMVrxjDvSuaI9Y9J+r+vzVTSCVwyInWU37bz+DwBegMqAAAAAAAAAAAAAAAAAAj6+InR5d/h3UILEr/2Q=="
                            alt=""
                          ></img>
                        )}

                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="angle-down"
                          className="svg-inline--fa fa-angle-down opacity-8 mt-1 ml-2"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          style={{ height: "1em" }}
                        >
                          <path
                            fill="currentColor"
                            d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                          />
                        </svg>
                      </button>
                     
                    </div>
                  </div>
                  <div className="widget-content-left  ms-3 header-user-info">
                    <div className="widget-heading">
                      {profile_data?.companyName} ({ profile_data?.marchentCode})
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {isModalOpen && ( */}
      {isModalOpen && (
        <div className="accountOverlay">
          <div
            tabIndex={-1}
            role="menu"
            aria-hidden="false"
            className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-end show"
            data-popper-placement="bottom-end"
            style={{
              // position: "absolute",
              position:"fixed",
              inset: "14px 38px auto auto",
              transform: "translate(0px, 44px)",
            }}
          >
            <ul className="nav flex-column">
           

              <li className="nav-item" style={{ padding: "0 15px" }}>
             
                <hr />
                <div
                  className="get-invoice-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/change-password");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Change Password
                </div>
                <hr />
              </li>
              <li className="nav-item" style={{ padding: "0 15px" }}>
                <div
                  className="ms-auto badge bg-warning"
                  onClick={logout}
                  style={{ cursor: "pointer" }}
                >
                  Logout
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
