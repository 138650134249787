import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

// Helpers
import { ApiHelperFunction } from '../../services/api/apiHelpers';

let initialState = {
  status: 'idle',
  profile_data: {},
  loginStatus: false,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getProfileDetails = createAsyncThunk('merchant_get_profile_details', async token => {
  try{
  let response = await ApiHelperFunction({
    urlPath: '/merchant/get-profile',
    method: 'GET',
    token,
  });

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error("Can't get data. Something went wrong");
  }
}catch(error){
}
});

export const ProfileSlice = createSlice({
  name: 'merchant_prefile_data',
  initialState,
  reducers: {
    clearState: state => {
      state.profile_data = {}
      state.loginStatus= false;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
    isLoggedIn: state => {
      state.loginStatus = true;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getProfileDetails.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getProfileDetails.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;
        state.profile_data = payload?.data;
      })
      .addCase(getProfileDetails.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState, isLoggedIn } = ProfileSlice.actions;

export default ProfileSlice.reducer;
