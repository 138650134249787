import { get, limitToLast, onChildAdded, push, query, ref, set, update } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { db } from '../../utils/FirebaseConfig';
import moment from 'moment';

export default function Footer() {
  const { profile_data } = useSelector(state => state.profileSlice);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [allMsg, setAllMsg] = useState([]);
  const [isMsgInit, setIsMsgInit] = useState(false);

  useEffect(() => {
    if (
      typeof profile_data !== "undefined" &&
      Object.keys(profile_data).length > 0 &&
      isMsgInit === false
    ) {
      setIsMsgInit(true);
      getChatWithAdmin();
    }
  }, [profile_data]);


  useEffect(() => {
    if (isChatOpen) {
      let chatBody = document.getElementById('chat_body');
      chatBody?.scrollTo(0, chatBody.scrollHeight);
    }
  }, [isChatOpen]);

  const getChatWithAdmin = () => {
    get(ref(db, `/chats/${profile_data?._id}/messages`)).then(snapshot => {
      if (snapshot.exists()) {
        updateDetails();
        setAllMsg(Object.values(snapshot.val()));
        chatListener();
      } else {
        chatListener();
      }
    });
  };

  const updateDetails = () => {
    let data = {
      name: `${profile_data?.firstName} ${profile_data?.lastName}`,
      image: profile_data?.image,
      id: profile_data?._id,
    };

    update(ref(db, `/chats/${profile_data?._id}`), data);
  };

  const chatListener = () => {
    onChildAdded(query(ref(db, `/chats/${profile_data?._id}/messages`), limitToLast(1)), snapshot => {
      setAllMsg(state => {
        if (snapshot.exists()) {
          let data = snapshot.val();
          let index = state.findIndex(it => it.id === data.id);
          if (index === -1) {
            return [...state, data];
          }
        }
        return state;
      });
      let chatBody = document.getElementById('chat_body');
      chatBody?.scrollTo(0, chatBody.scrollHeight);
    });
  };

  const sendMessage = () => {
    if (msg === '') return;

    let chatRef = push(ref(db, `/chats/${profile_data?._id}/messages`));
    let data = {
      sendBy: 'merchant',
      message: msg,
      roomId: profile_data?._id,
      id: chatRef.key,
      sendTime: moment().format(),
    };

    if (allMsg.length === 0) {
      updateDetails();
    }

    set(chatRef, data)
      .then(() => {
        update(ref(db, `/chats/${profile_data?._id}`), {
          lastMsg: data.message,
          lastMsgTime: data.sendTime,
          unseen: true,
        });
        let chatBody = document.getElementById('chat_body');
        chatBody?.scrollTo(0, chatBody.scrollHeight);
        setMsg('');
      })
      .catch(error => {
      });
  };

  return (
    <>
      <div className="app-footer">
       

        <div className="chat-container">
          <div
            className="d-flex align-items-center justify-content-between chat-header"
            onClick={() => setIsChatOpen(state => !state)}>
            <div className="d-flex align-items-center" style={{ height: 50 }}>
              <button
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                type="button"
                aria-haspopup="true"
                aria-expanded="false"
                className="p-0 btn btn-link"
              // onClick={hankclickModal}
              >
                <img
                  width={42}
                  className="rounded-circle mt-1"
                  src={'https://img.freepik.com/free-icon/user_318-159711.jpg'}
                  alt=""
                />
              </button>

              <h6
                style={{
                  margin: 0,
                  marginLeft: 10,
                }}>
                {isChatOpen ? 'Admin' : 'Chat with admin'}
              </h6>
            </div>

            <i className={`fa ${isChatOpen ? 'fa-angle-down' : 'fa-angle-up'}`} />
          </div>

          {isChatOpen ? (
            <>
              <div className="chat-body" id="chat_body">
                {allMsg.map((item, index) => {
                  return (
                    <div
                      className={`d-flex chat-message-container ${item.sendBy === 'merchant' ? 'justify-content-end' : ''
                        }`}
                      key={index}>
                      <div className={`chat-message ${item.sendBy === 'merchant' ? 'own-message' : ''}`}>
                        <h5 style={{ overflowWrap: 'anywhere' }}>{item.message}</h5>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="chat-footer d-flex justify-content-between align-items-center">
                <input
                  // className="msgBox"
                  placeholder="Write a message"
                  onChange={e => setMsg(e.nativeEvent.target.value)}
                  value={msg}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      sendMessage();
                    }
                  }}
                />

                <i
                  className="fa fa-paper-plane ml-3 mr-1"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={sendMessage}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
