// Hooks
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

// Utilities
import { getOnlyFullDate } from "../../../utils/date-functions";
import { apiCallSuccess, apiCallError } from "../../../utils/common-utils";

// Redux actions
import {
  getAllActivities,
  getActivityAvailabilityForADate,
  getAvailabilityForAMonth,
} from "../../../redux/adminSlice/activitySlice";

// API Helpers
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import Calendar from "../../../Calendar/Calendar";
import { changeflag } from "../../../redux/merchantSlice/ControlTableSlice";
// import ControlTableSlice

import "./styles.css";
import moment from "moment";

function ActivityAvailability() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [currentDate, setcurrentDate] = useState(new Date());
  const [error, setError] = useState("");
  const [bookingsForAMonth, setbookingsForAMonth] = useState([]);
  const [itemId, setitemId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [tabValue, setTabvalue] = useState("");
  const [expireValue, setExpireValue] = useState("");
  const flag = true;
  // Redux stores
  const { activities, slots_for_the_month, slots_for_a_date } = useSelector(
    (state) => state.adminActivityActionSlice
  );
  // const { flag } = useSelector((state) => state.controlTable);


  // Component did mount
  useEffect(() => {
    dispatch(getAllActivities());
  }, [dispatch]);

  const openModal = () => {
    setModal(true);
  };

  const getBookingsForAMonth = async (id) => {
    try {
      let res = await JSON_API["getActivityAvailabilityForADate"]({
        id: id,
        date: getOnlyFullDate(new Date()),
      });

      if (res?.isSuccess) {
        setbookingsForAMonth(res.data.data);
      } else {
        apiCallError(res?.error?.msg, setError);
      }
    } catch (error) {
      toast.error(
        "Something went wrong while fetching bookings data for the activity"
      );
    }
  };

  const filteredData = useMemo(() => {
    if (searchText != "") {
      let filter = activities?.filter((item, index) => {
        return JSON.stringify(item)
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      return filter;
    } 


    if (tabValue) {
      const today = moment();
      let tabInfo = [];
      if (tabValue === "bookable") {
        tabInfo = activities?.filter((item) => {
          const startDate = moment(item?.startDate);

          return startDate < today;
        });
      } else {
        tabInfo = activities?.filter((item) => {
          const endDate = moment(item?.endDate);

          return endDate > today;
        });
      }

      return tabInfo;
    } else {
      return activities;
    }
  }, [searchText, tabValue]);


  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 50,
      },
      {
        label: "Tour Code",
        field: "tour_code",
        sort: "asc",
        width: 100,
      },
      {
        label: "Tour Name",
        field: "activity_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Price",
        field: "price",
        sort: "asc",
        width: 100,
        height: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 130,
      },
    ],
    rows:
      filteredData?.length > 0
        ? filteredData.map((item, idx) => {
            return {
              sl: idx + 1,
              tour_code: (
                <div className="widget-content p-0">
                  {item?.productCode}
                </div>
              ),
              activity_name: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="zwidget-heading"
                        style={{ opaactivity: 1, textAlign: "center" }}
                      >
                        {item?.activityTitle}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              price: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="zwidget-heading"
                        style={{ opaactivity: 1, textAlign: "center" }}
                      >
                        {item?.activityActualPrice}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              action: (
                <div>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-primary"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(0);
                      setitemId(item?._id);
                      // getBookingsForAMonth(item?._id);
                      dispatch(
                        getAvailabilityForAMonth({
                          year: new Date().getFullYear(),
                          month: new Date().getMonth() + 1,
                          activityId: item?._id,
                        })
                      );
                    }}
                  >
                    <i className="fa-regular fa fa-eye"></i>
                  </button>
                </div>
              ),
            };
          })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Date</h5>
            </div>
            <div style={{ height: "10px" }}>
              <Calendar
                dateData={slots_for_the_month}
                onDateClick={(date) => {
                  setmodalToggleView(1);
                  dispatch(
                    getActivityAvailabilityForADate({
                      date,
                      activityId: itemId,
                    })
                  );
                }}
                onDateHover={(date) => {}
              }
                nextMonthClick={(data) =>
                  dispatch(
                    getAvailabilityForAMonth({
                      year: data?.year,
                      month: data?.month,
                      activityId: itemId,
                    })
                  )
                }
                prevMonthClick={(data) =>
                  dispatch(
                    getAvailabilityForAMonth({
                      year: data?.year,
                      month: data?.month,
                      activityId: itemId,
                    })
                  )
                }
              />
            </div>
          </div>
        );
      case 1:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{`Check All The Slots For`}</h5>
            </div>
            <div className="modal-body">
              {slots_for_a_date.length > 0 ? (
                slots_for_a_date.map((item, idx) => {
                  return (
                    <div className="row" key={idx}>
                      <div className="slot-detail">
                        <div className="col-lg-4 col-md-6 col-12">
                          <div className="DetailsData">
                            <span>Date</span>
                            <h6>{item?.tourDate}</h6>
                          </div>
                          <div className="DetailsData">
                            <span>Remaining Seats</span>
                            <h6>{item?.remeningUser}</h6>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                          <div className="DetailsData">
                            <span>Time</span>
                            <h6>{item?.time}</h6>
                          </div>
                        </div>
                        <div className="col-g-4 col-md-6 col-12">
                          <div className="DetailsData">
                            <span>Price Per Person</span>
                            <h6>{item?.changePrice}</h6>
                          </div>
                        </div>
                      </div>
                      <button
                        className="btn btn-primary col-3 edit-slot"
                        onClick={() =>
                          navigate(
                            `/edit-product-availability/${itemId}/${item?._id}?date=${item?.tourDate}&time_slot=${item?.time}`
                          )
                        }
                      >
                        Edit
                      </button>
                    </div>
                  );
                })
              ) : (
                <p>Currently there are no slots for this date.</p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Table
        striped
        tableTitle="All Products"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={(e) => setSearchText(e.target.value)}
        tabValue={tabValue}
        // getBookValue={setTabvalue}
        tabflag={flag}
      />
    </>
  );
}

export default ActivityAvailability;
