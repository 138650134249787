export const checkFileDimension = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        let fileObj = {}
        reader.onload = function (e) {
            const img = new Image();
            img.onload = function () {
                let width = img.naturalWidth;
                let height = img.naturalHeight;
                fileObj.width = width;
                fileObj.height = height;
                resolve(fileObj);
            };
            img.src = e.target.result;

        };
        reader.readAsDataURL(file);
    })
};