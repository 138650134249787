import { toast } from "react-toastify";

export const getTokens = () => {
  return {
    Authorization: localStorage.getItem("Authorization"),
    userType: localStorage.getItem("userType"),
    "Access-Control-Allow-Origin": "*",
  };
};

export const getTypes = (value, body) => {
  if (value.params) {
    if (typeof body == "object") {
      return { params: { id: body.id }, body: body };
    } else {
      return { params: null, body: body };
    }
  } else if (value.query) {
    return { query: body };
  }

  return {};
};

export const apiCallSuccess = (data) => {
  toast.success("Action successful!");
};

export const apiCallError = (errorMsg, setError) => {
  toast.error(errorMsg);
  setError(errorMsg);
};
export const capitalizeFirstLetter2 = (text) => {
  const sentence = text;
  const words = sentence?.split(" ");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });

  // Reconstruct the sentence with capitalized words
  const updatedSentence = capitalizedWords.join(" ");

  return updatedSentence;
};

export function extractKeys(sourceObject, targetObject) {
  const extractedKeyValues = {};

  // Iterate over the keys of the sourceObject
  Object.keys(sourceObject).forEach((key) => {
    // Check if the key exists in the targetObject
    if (targetObject.hasOwnProperty(key)) {
      // If the key exists in the targetObject, add it to the extractedKeyValues object
      extractedKeyValues[key] = targetObject[key];
    }
  });

  return extractedKeyValues;
}

// export function extractDifferentValues(sourceObject, targetObject) {
//   const differentValues = {};

//   // Iterate over the keys of the sourceObject
//   Object.keys(sourceObject).forEach(key => {
//     // Check if the key exists in both sourceObject and targetObject
//     if (targetObject.hasOwnProperty(key)) {
//       // Compare the values of the keys in both objects
//       if (JSON.stringify(sourceObject[key])  !== JSON.stringify(targetObject[key]) ) {
//         // If values are different, add the key-value pair to differentValues object
//         differentValues[key] = targetObject[key];
//       }
//     }
//   });

//   return differentValues;
// }

export function extractDifferentValues(sourceObject, targetObject) {
  const differentValues = {};
  let changesDetected = false;

  // Iterate over the keys of the sourceObject
  Object.keys(sourceObject).forEach(key => {
    // Check if the key exists in the targetObject
    if (targetObject.hasOwnProperty(key)) {
      // Compare the values of the keys in both objects
      if (JSON.stringify(sourceObject[key]) !== JSON.stringify(targetObject[key])) {
        // If values are different, add the key-value pair to differentValues object
        differentValues[key] = targetObject[key];
        changesDetected = true;
      }
    } else {
      // If the key doesn't exist in the targetObject, add it
      differentValues[key] = targetObject[key] || sourceObject[key];
      changesDetected = true;
    }
  });

  // If no changes were detected, return an empty object
  return changesDetected ? differentValues : {};
}


export function getFileTypeFromUrl(url) {
  const extension = url.split('.').pop().toLowerCase();
  if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
      return 'Image';
  } else if (extension === 'pdf') {
      return 'PDF';
  } else {
      return 'Unknown';
  }
}


export function formatTime(second) {
  let hours = Math.floor(second / 3600);
  let minutes = Math.floor((second % 3600) / 60);
  let seconds = Math.floor(second % 60);
  return (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}

export function getVideoDuration(videoFile) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function(event) {
      const blob = new Blob([event.target.result], { type: videoFile.type });
      const video = document.createElement('video');
      const url = URL.createObjectURL(blob);

      video.addEventListener('loadedmetadata', function() {
        const duration = video.duration;
        resolve(duration);
      });

      video.src = url;
    };

    reader.onerror = function(error) {
      reject(error);
    };

    reader.readAsArrayBuffer(videoFile);
  });
}


