// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// Utils
import { apiCallError, formatTimeToAMPM } from "../../../utils";

// Helpers
import { toast } from "react-toastify";
import moment from "moment";

// API
import { requestData } from "../../../services/api/apiHelpers";

// Redux actions
import {
  getAllActivities,
  getActivityAvailabilityForADate,
} from "../../../redux/adminSlice/activitySlice";

// Components
import TimePicker from "react-time-picker";
import Loader from "../../../Component/Loader";
import Select from "react-select";

import "react-time-picker/dist/TimePicker.css";
import "./styles.css";

function EditAvailability() {
  let initialValues = {
    activityDetailsId: "",
    activityDetailsName: "",
    tourDate: "",
    time: "",
    cutoffTime: "",
    changePrice: 0,
    remeningUser: 0,
    status: "",
  };

  let createErrors = {
    time: "",
    cutoffTime: "",
    changePrice: "",
    remeningUser: "",
  };

  const personType = [
    { label: "Adult", value: "adult" },
    { label: "Child/Children", value: "child/children" },
  ];

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [activity, setactivity] = useState(initialValues);
  const [timeSlots, settimeSlots] = useState([]);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const [active, setActive] = useState(false);

  // Redux stores
  const { slots_for_a_date, activities } = useSelector(
    (state) => state.adminActivityActionSlice
  );
  const singleData = slots_for_a_date?.find(
    (item) => item._id === params.slot_id
  );


  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);

  // Component did mount
  useEffect(() => {
    const slot_date = queryParams.get("date");
    const slot_time = queryParams.get("time_slot");
    dispatch(
      getActivityAvailabilityForADate({
        date: slot_date,
        activityId: params.acti_id,
      })
    );
    dispatch(getAllActivities());

    // Use the retrieved data to set the activity state
    setactivity({
      activityDetailsId: singleData.activityDetailsId,
      activityDetailsName: singleData.activityDetailsName,
      tourDate: singleData.tourDate,
      time: singleData.time,
      cutoffTime: singleData.cutoffTime,
      changePrice: singleData.changePrice,
      remeningUser: singleData.remeningUser,
      status: singleData.status, // Assuming status is a string
    });

    settimeSlots(
      slots_for_a_date.map((item) => item?.time !== slot_time && item?.time)
    );
  }, []);


  let activityValue = activities?.find((item) => item?._id === params.acti_id);

  const handleTimeChange = (time) => {
    let formattedTime = formatTimeToAMPM(time);
    setactivity({ ...activity, time: formattedTime });
  };

  const allowOnlyPositiveInteger = (event) => {
    event.preventDefault();

    let inputValue = event.target.value.replace(/\D/g, "");

    if (inputValue !== "") {
      inputValue = parseInt(inputValue, 10);

      if (inputValue <= 0 || isNaN(inputValue)) {
        inputValue = "";
      }
    }

    setactivity({ ...activity, [event.target.name]: inputValue });
  };

  const handleValidation = () => {
    const { time, cutoffTime, changePrice, remeningUser } = activity;

    if (time === "") {
      seterrors({ ...createErrors, time: "Start time is required." });

      return false;
    }
    if (timeSlots.includes(time)) {
      seterrors({
        ...createErrors,
        time: "This start time is already available.",
      });

      return false;
    }
    if (cutoffTime === "") {
      seterrors({ ...createErrors, cutoffTime: "Cutoff time is required" });

      return false;
    }
    if (moment(time, "h:mm A").isBefore(moment(cutoffTime, "h:mm A"))) {
      seterrors({
        ...createErrors,
        cutoffTime: "CutOff time must be before start time",
      });

      return false;
    }
    // if (changePrice === 0) {
    //   seterrors({
    //     ...createErrors,
    //     changePrice: "Change price is required.",
    //   });

    //   return false;
    // }
    if (remeningUser === 0) {
      seterrors({
        ...createErrors,
        remeningUser: "Number of remaining seats is required.",
      });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (handleValidation()) {
      if (activity.remeningUser <= activityValue?.tourPerson) {
        try {
          setisLoading(true);
          let res = await requestData(
            `/merchant/edit-availability/${params.slot_id}`,
            "PUT",
            activity
          );

          if (res && res.status) {
            dispatch(
              getActivityAvailabilityForADate({
                date: queryParams.get("date"),
                activityId: params.acti_id,
              })
            );
            seterrors(createErrors);
            setactivity(initialValues);
            setisLoading(false);
            // apiCallSuccess(res.data);
            toast.success("Product availability edited successfully");
            navigate("/check-product-availability");
          } else {
            setisLoading(false);
            apiCallError(res?.error?.message, setError);
          }
        } catch (error) {
          setisLoading(false);
          apiCallError(error.message, setError);
        }
      } else {
        toast.error(
          `Available seats cannot be more than ${activityValue.tourPerson} for this  activity.`
        );
        return;
      }
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };


  return (
    <>
      {isLoading && <Loader />}
      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Change Slot Details</div>
              {singleData?.isApprove === true ? (
                " "
              ) : (
                <h5 style={{ backgroundColor: "red", color:"black", padding: "4px" }}>
                  Your changes has been sent to admin for approval, Please wait
                  for its approval.
                </h5>
              )}
              <div className="row">
                {/* Activity* */}
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="activityDetailsId"
                      className="form-label font-weight-bold"
                    >
                      Activity*
                    </label>
                    <Select
                      isDisabled
                      name="activityDetailsId"
                      id="activityDetailsId"
                      classNamePrefix="select"
                      options={activities?.map((item) => ({
                        value: item?._id,
                        label: item?.activityTitle,
                      }))}
                      value={{
                        value: activityValue?._id,
                        label: activityValue?.activityTitle,
                      }}
                      // isMulti
                    />
                  </div>
                </div>

                {/* Date* */}
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="tourDate"
                    >
                      Date*
                    </label>
                    <input
                      name="tourDate"
                      id="tourDate"
                      type="text"
                      className="form-control"
                      value={activity.tourDate}
                      disabled
                    />
                  </div>
                </div>

                {/* Start Time* */}
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="tourDate"
                    >
                      Start Time*
                    </label>
                    <TimePicker
                      id="time"
                      clockIcon={null}
                      clearIcon={null}
                      className="form-control"
                      hourPlaceholder="hh"
                      minutePlaceholder="mm"
                      value={activity.time}
                      format="hh:mm a"
                      openClockOnFocus={false}
                      // onChange={handleTimeChange}
                      onChange={(time) => {
                        // let formattedTime = formatTimeToAMPM(time);
                        let formattedTime =
                          typeof time === "string"
                            ? formatTimeToAMPM(time)
                            : formatTimeToAMPM(time.format("HH:mm"));

                        setactivity((prev) => {
                          let update = { ...prev };
                          update.time = formattedTime;

                          return JSON.parse(JSON.stringify(update));
                        });
                      }}
                    />
                  </div>
                  {singleData?.time !==
                  singleData?.tempAvailability?.[0]?.time ? (
                    <h5 style={{ backgroundColor: "yellow" }}>
                      {singleData?.tempAvailability?.[0]?.time}
                    </h5>
                  ) : (
                    ""
                  )}
                  {errors.time && (
                    <span className="errorMessageText text-danger">
                      {errors.time}
                    </span>
                  )}
                </div>

                {/* Booking Cutoff Time* */}
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="tourDate"
                    >
                      Booking Cutoff Time*
                    </label>
                    <TimePicker
                      id="time"
                      clockIcon={null}
                      clearIcon={null}
                      className="form-control"
                      hourPlaceholder="hh"
                      minutePlaceholder="mm"
                      value={activity.cutoffTime}
                      format="hh:mm a"
                      openClockOnFocus={false}
                      onChange={(time) => {
                        let formattedTime = formatTimeToAMPM(time);

                        setactivity((prev) => {
                          let update = { ...prev };
                          update.cutoffTime = formattedTime;

                          return JSON.parse(JSON.stringify(update));
                        });
                      }}
                    />
                  </div>
                  {singleData?.cutoffTime !==
                  singleData?.tempAvailability?.[0]?.cutoffTime ? (
                    <h5 style={{ backgroundColor: "yellow" }}>
                      {singleData?.tempAvailability?.[0]?.cutoffTime}
                    </h5>
                  ) : (
                    ""
                  )}
                  {errors.cutoffTime && (
                    <span className="errorMessageText text-danger">
                      {errors.cutoffTime}
                    </span>
                  )}
                </div>

                {/* Price To Set* */}
                {/* <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="changePrice"
                      className="form-label font-weight-bold"
                    >
                      Price To Set*
                    </label>
                    <input
                      name="changePrice"
                      id="changePrice"
                      type="number"
                      min={"0"}
                      className="form-control"
                      autoComplete="false"
                      value={activity.changePrice}
                      onChange={allowOnlyPositiveInteger}
                    />
                  </div>
                  {errors.changePrice && (
                    <span className="errorMessageText text-danger">
                      {errors.changePrice}
                    </span>
                  )}
                </div> */}

                {/* Remaining Seats* */}
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="remeningUser"
                      className="form-label font-weight-bold"
                    >
                      Remaining Seats*
                    </label>
                    <input
                      name="remeningUser"
                      id="remeningUser"
                      type="number"
                      min={"0"}
                      className="form-control"
                      autoComplete="false"
                      value={activity.remeningUser}
                      onChange={allowOnlyPositiveInteger}
                    />
                  </div>
                  {singleData?.remeningUser !==
                  singleData?.tempAvailability?.[0]?.remeningUser ? (
                    <h5 style={{ backgroundColor: "yellow", marginTop:"-12px" }}>
                      {singleData?.tempAvailability?.[0]?.remeningUser}
                    </h5>
                  ) : (
                    ""
                  )}
                  {errors.remeningUser && (
                    <span className="errorMessageText text-danger">
                      {errors.remeningUser}
                    </span>
                  )}
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  {/* { ( */}
                  {activity?.status ? (
                    <button
                      className="mt-2 btn btn-danger d-table"
                      style={{ marginRight: "40px" }}
                      type="submit"
                      onClick={() => {
                        setactivity((prevActivity) => ({
                          ...prevActivity,
                          status: "false", // Update status to "false"
                        }));
                      }}
                    >
                      Inactive
                    </button>
                  ) : (
                    <button
                      className="mt-2 btn btn-primary d-table"
                      style={{ marginRight: "10px" }}
                      type="submit"
                      onClick={() => {
                        setactivity((prevActivity) => ({
                          ...prevActivity,
                          status: "true",
                        }));
                      }}
                    >
                      Active
                    </button>
                  )}

                  {/* ) : ( */}

                  {/* )} */}
                </div>

                <div style={{ display: "flex" }}>
                  <button
                    className="mt-2 btn btn-secondary d-table"
                    onClick={() => navigate("/check-product-availability")}
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </button>
                  <button
                    className="mt-2 btn btn-primary d-table"
                    type="submit"
                    onClick={() => {}}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditAvailability;
