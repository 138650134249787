import React from "react";
import { ThreeDots,  } from "react-loader-spinner";

const Loader = (props) => {
  return (
    <span
      style={{
        display: "flex",
        position: "fixed",
        zIndex: "1000",
        left: "0",
        top: "0",
        right: "0",
        margin: "0 auto",
        background: "rgba(0,0,0,0.3)",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#48D1CC"
        ariaLabel="three-dots-loading"
        wrapperStyle={{
          dispay: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        wrapperClassName=""
        visible={true}
      />
    </span>
  );
};

export default Loader;
