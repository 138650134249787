import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../services/api/apiHelpers";


let initialState = {
  status: "idle",
  activity_types: [],
  activity_sites: [],
  sites_of_a_city: [],
  site_destinations: [],
  activities: [],
  draft_activities: [],
  slots_for_a_date: [],
  slots_for_the_month: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAllActivityTypes = createAsyncThunk(
  "get_all_activity_types",
  async () => {

    try{
    let response = await ApiHelperFunction({
      urlPath: "/admin/admin-view-activity-type",
      method: "GET",
    });

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }catch(error){
  }
  }
);


export const getAllActivitySites = createAsyncThunk(
  "get_all_activity_sites",
  async () => {

    try{
    let response = await ApiHelperFunction({
      urlPath: "/admin/viewActivitySite",
      method: "GET",
    });

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }

  }catch(error){
        }
  }
);

export const getAllSitesForACity = createAsyncThunk(
  "get_all_sites_for_a_city",
  async (cityId) => {
    try{
    let response = await ApiHelperFunction({
      urlPath: `/merchant/sitename-against-city/${cityId}`,
      method: "GET",
    });

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }catch(error){
        }
  }
);

export const getAllSiteDestinations = createAsyncThunk(
  "get_all_site_destinations",
  async (site_id) => {
try{

    let response = await ApiHelperFunction({
      urlPath: `/merchant/view-destination/${site_id}`,
      method: "GET",
    });

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }catch(error){

  }
  }
);

export const getAllActivities = createAsyncThunk(
  "get_all_activities",
  async (params) => {
  try{
    let response = await ApiHelperFunction({
      urlPath: params
        ? `/merchant/viewActivityDetails/${params}`
        : `/merchant/viewActivityDetails`,
      method: "GET",
    });

    if (response?.status === 200) {
      return response?.data;
    } else {
      toast.error("Can't get Activity data. Something went wrong");
    }
  }catch(error){
  }
  }
);

export const getAllDraftActivities = createAsyncThunk(
  "get_all_draft_activities",
  async () => {

    try{
    let response = await ApiHelperFunction({
      urlPath: "/merchant/viewDraftActivityDetails",
      method: "GET",
    });

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }catch(error){
        }
  }
);

export const getActivityAvailabilityForADate = createAsyncThunk(
  "get_activity_availability_for_a_date",
  async (data = { date: "", activityId: "" }) => {
    try{
    let response = await ApiHelperFunction({
      urlPath: "/merchant/view-availability",
      method: "POST",
      data: {
        tourDate: data.date,
        id: data.activityId,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.error?.data?.message);
    }
  }catch(error){
  }
  }
);

export const getAvailabilityForAMonth = createAsyncThunk(
  "get_availability_for_a_month",
  async (data = { year: "", month: "", activityId: "" }) => {
    try{
    let response = await ApiHelperFunction({
      urlPath: "/merchant/view-monthwise-availability",
      method: "POST",
      data: {
        year: data.year,
        month: data.month,
        id: data.activityId,
      },
    });

    if (response.status === 200) {
      return response?.data;
    } else {
      toast.error(response?.error?.data?.message);
    }
  }catch(error){
  }
  }
);

export const ActivitiesSlice = createSlice({
  name: "activity_actions",
  initialState,
  reducers: {
    activityActionsClearState: (state) => {
      state.status = "idle";
      state.activity_types = [];
      state.activity_sites = [];
      state.sites_of_a_city = [];
      state.site_destinations = [];
      state.activities = [];
      state.draft_activities = [];
      state.slots_for_a_date = [];
      state.slots_for_the_month = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllActivityTypes.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllActivityTypes.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
        state.activity_types = payload.data;
      })
      .addCase(getAllActivityTypes.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
      })
      .addCase(getAllActivitySites.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllActivitySites.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.activity_sites = payload.data;
      })
      .addCase(getAllActivitySites.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAllSitesForACity.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllSitesForACity.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.sites_of_a_city = payload?.data;
      })
      .addCase(getAllSitesForACity.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAllSiteDestinations.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllSiteDestinations.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.site_destinations = payload?.data;
      })
      .addCase(getAllSiteDestinations.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAllActivities.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllActivities.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.activities = payload?.data;
      })
      .addCase(getAllActivities.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAllDraftActivities.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllDraftActivities.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.draft_activities = payload.data;
      })
      .addCase(getAllDraftActivities.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getActivityAvailabilityForADate.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        getActivityAvailabilityForADate.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          state.isSuccess = true;
          state.slots_for_a_date = payload?.data;
        }
      )
      .addCase(getActivityAvailabilityForADate.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAvailabilityForAMonth.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAvailabilityForAMonth.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.slots_for_the_month = payload?.data;
      })
      .addCase(getAvailabilityForAMonth.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { activityActionsClearState } = ActivitiesSlice.actions;

export default ActivitiesSlice.reducer;
