import React, { useMemo } from "react";
import Select from "react-select";

const getLable = (option, labelKey) => {
  let labelName = "";
  labelKey.forEach((element) => {
    labelName = labelName + option[element] + " ";
  });
  return labelName;
};
function SelectInput({
  disabled = false,
  label = "",
  className = "",
  id,
  options = [],
  labelKey = "",
  valueKey = "",
  value,
  onChange = ()=>{},
  noOptionsMessage
}) {
  const SelectValue = useMemo(() => {
    let data = {
      label: "",
      value: "",
    };

    let findFromOptions = options.find((item) => item[valueKey] === value);
    if (findFromOptions) {
      data.label = Array.isArray(labelKey)
        ? getLable(findFromOptions, labelKey)
        : findFromOptions[labelKey];
      data.value = findFromOptions[valueKey];
    }
    return data;
  }, [value, options]);

  return (
    <div className={className}>
      <div className="mb-3">
        <label htmlFor="countryId" className="form-label font-weight-bold">
          {label}
        </label>
        <Select
            isDisabled={disabled}
          name={id}
          id={id}
          classNamePrefix="select"
          options={options?.map((option, idx) => ({
            value: option[valueKey],
            label: Array.isArray(labelKey)
              ? getLable(option, labelKey)
              : option[labelKey],
          }))}
          // isMulti
          value={SelectValue}
          onChange={(val) => {
            onChange(options.find((item) => item[valueKey] === val.value));
          }}
          noOptionsMessage={noOptionsMessage}
        />
      </div>
      {/* {errors.countryId && (
      <span className="errorMessageText text-danger">
        {errors.countryId}
      </span>
    )} */}
    </div>
  );
}

export default SelectInput;
