import  { useEffect } from "react";
import { useLocation } from "react-router-dom";

function GoToTop() {
  const location = useLocation();
  useEffect(() => {
    toTop();
  }, [location.pathname]);

  function toTop() {
    const top = document.getElementById("root");
    top.scrollIntoView({ behavior: "smooth" });
  }
}

export default GoToTop;
