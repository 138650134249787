// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Utils
import {
  apiCallError,
  formatTimeToAMPM,
} from "../../../utils";

// Helpers
import { toast } from "react-toastify";
import moment from "moment";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

import { getAllSpecialOffers } from "../../../redux/merchantSlice/specialOfferSlice";
import { getAllActivities } from "../../../redux/adminSlice/activitySlice";

// Components
import Loader from "../../../Component/Loader";
import TimePicker from "react-time-picker";
// import DateTimePicker from "react-datetime-picker";
import Datetime from "react-datetime";
import Select from "react-select";

// import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-datetime/css/react-datetime.css";

let initialValues = {
  specialOfferName: "",
  duration_value: 0,
  duration_unit: "",
  activityDetailsId: [],
  offerType: "",
  discountPercentage: 0,
  description: "",
  startDate: "",
  limitedStartDate: "",
  limitedEndDate: "",
  limitedOfTime: "",
  earlyNoOfDays: "",
  lastMinuteValue: "",
  lastMinuteUnit: "",

  daySlot: [],
  slotStartDate: "",
  slotEndDate: "",
};

const createErrors = {
  specialOfferName: "",
  duration_value: "",
  duration_unit: "",
  activityDetailsId: "",
  offerType: "",
  discountPercentage: "",
};

var numRegXp = /^[0-9]+$/;
const duration_units = ["minutes", "hours", "days"];

const EditSpecialOffer = () => {
  // export default function AddSpecialOffers() {
  const dispatch = useDispatch();
  const datetimeInputRef = useRef(null);
  const [dateMomentObj, setdateMomentObj] = useState(null);
  const [banner, setbanner] = useState(initialValues);
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const [itemIndex, ] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const [openDate, setOpendate] = useState(false);
  const [selectedDateTime, ] = useState(new Date());

  const weekDaysOptions = [
    { value: "Sunday", label: "Sunday" },
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
  ];

  // Redux stores
  const { special_offers } = useSelector((state) => state.specialOfferSlice);
  const offerData = special_offers.find((item) => item?._id === params.id);
  const nextDay = moment(selectedDateTime).add(1, "days").format("YYYY-MM-DD");
  useEffect(() => {
    dispatch(getAllSpecialOffers());
    dispatch(getAllActivities());
    setbanner({
      id: offerData._id,
      specialOfferName: offerData.specialOfferName,
      duration_value: offerData.duration.value,
      duration_unit: offerData.duration.unit,
      activityDetailsId: offerData.included_activity,
      offerType: offerData.offerType,
      discountPercentage: offerData.discountPercentage,
      description: offerData.description,

      limitedOfTime: offerData.limitedOfTime,
      limitedStartDate: offerData.limitedStartDate,
      limitedEndDate: offerData.limitedEndDate,

      lastMinuteUnit: offerData.lastMinuteUnit,
      lastMinuteValue: offerData.lastMinuteValue,

      earlyNoOfDays: offerData.earlyNoOfDays,

      daySlot: offerData.daySlot,
      slotStartDate: offerData.slotStartDate,
      slotEndDate: offerData.slotStartDate,
      // startDate: offerData.startDate, // new Date(offerData.startDate).toUTCString(),
    });
    /** ------ To set the startDate to the appropriate value format for "DateTime" component ----- */
    const createMomentObj = () => {
      const dateString = offerData?.startDate;
      const momentObj = moment(dateString);

      // Set the properties as specified
      momentObj._isAMomentObject = true;
      momentObj._strict = true;
      momentObj._isUTC = false;
      momentObj._pf = {
        empty: false,
        unusedTokens: [],
        unusedInput: [],
        overflow: -2,
        charsLeftOver: 0,
        nullInput: false,
        invalidEra: null,
        invalidMonth: null,
        invalidFormat: false,
        userInvalidated: false,
        iso: false,
        parsedDateParts: [],
        era: null,
        meridiem: null,
        rfc2822: false,
        weekdayMismatch: false,
      };
      momentObj._locale = {
        _calendar: {
          sameDay: "[Today at] LT",
          nextDay: "[Tomorrow at] LT",
          nextWeek: "dddd [at] LT",
          lastDay: "[Yesterday at] LT",
          lastWeek: "[Last] dddd [at] LT",
          sameElse: "L",
        },
        _longDateFormat: {
          LTS: "h:mm:ss A",
          LT: "h:mm A",
          L: "MM/DD/YYYY",
          LL: "MMMM D, YYYY",
          LLL: "MMMM D, YYYY h:mm A",
          LLLL: "dddd, MMMM D, YYYY h:mm A",
        },
        _invalidDate: "Invalid date",
        _dayOfMonthOrdinalParse: {},
        _relativeTime: {
          future: "in %s",
          past: "%s ago",
          s: "a few seconds",
          ss: "%d seconds",
          m: "a minute",
          mm: "%d minutes",
          h: "an hour",
          hh: "%d hours",
          d: "a day",
          dd: "%d days",
          w: "a week",
          ww: "%d weeks",
          M: "a month",
          MM: "%d months",
          y: "a year",
          yy: "%d years",
        },
        _months: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        // ... (Other properties)
      };

      momentObj._d = new Date(dateString);
      momentObj._isValid = true;

      setdateMomentObj(new Date(momentObj));
    };
    /** -----------------------------------------------------------------------------------------  */

    createMomentObj();
  }, []);

  const handleDateTimeChange = (date) => {
    setdateMomentObj(moment(date).format());
  };

  const handleChange = (event) => {
    event.preventDefault();
    if (
      event.target.name === "duration_value" ||
      event.target.name === "discountPercentage"
    ) {
      if (!event.target.value.match(numRegXp)) {
        event.target.value = "";
      }
    }

    setbanner({ ...banner, [event.target.name]: event.target.value });
  };

  const handleValidation = () => {
    const {
      specialOfferName,
      duration_value,
      duration_unit,
      offerType,
      discountPercentage,
    } = banner;

    if (specialOfferName === "") {
      seterrors({
        ...errors,
        specialOfferName: "Special offer name is required",
      });

      return false;
    }
    if (duration_value === 0 && duration_unit === "") {
      seterrors({ ...errors, duration_value: "Offer duration is required" });

      return false;
    }
    if (offerType === "") {
      seterrors({
        ...errors,
        offerType: "Offer type is required",
      });

      return false;
    }
    if (discountPercentage === 0) {
      seterrors({
        ...errors,
        discountPercentage: "Discount percentage is required",
      });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      let bodyData = {
        id: special_offers[itemIndex]?._id,
        ...banner,
        startDate: dateMomentObj,
        duration: { value: banner.duration_value, unit: banner.duration_unit },
      };
      delete bodyData?.duration_value;
      delete bodyData?.duration_unit;


      try {
        setisLoading(true);
        let res = await JSON_API["adminEditSpecialOffer"](bodyData);

        if (res.isSuccess) {
          dispatch(getAllSpecialOffers());
          seterrors(createErrors);
          setdateMomentObj(null);
          if (datetimeInputRef.current) {
            datetimeInputRef.current.value = "";
          }
          setbanner(initialValues);
          setisLoading(false);
          toast.success("Special offer updated successfully ");
          navigate("/manage-special-offers");
        } else {
          setisLoading(false);
          apiCallError(res?.error?.message, );
        }
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, );
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <form className="" onSubmit={handleSubmit}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Edit Details</div>
              <div className="row" style={{ margin: "0.1rem" }}>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="specialOfferName" className="form-label">
                      Special Offer Name*
                    </label>
                    <input
                      type="text"
                      name="specialOfferName"
                      id="specialOfferName"
                      // placeholder="Your first name, e.g. Walter"
                      className="form-control"
                      autoComplete="false"
                      value={banner.specialOfferName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.specialOfferName && (
                    <span className="errorMessageText text-danger">
                      {errors.specialOfferName}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Offer Starts At*</label>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="duration_value"
                          id="duration_value"
                          // placeholder="Your first name, e.g. Walter"
                          className="form-control"
                          autoComplete="false"
                          onFocus={() => setOpendate(true)}
                          value={moment(dateMomentObj).format("YYYY-MM-DD")}
                        />
                      </div>
                      <div className="col-md-6">
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => setOpendate(!openDate)}
                        >
                          Select
                        </button>
                      </div>
                    </div>

                    {openDate && (
                      <Datetime
                        // renderInput={renderInput}
                        // utc={true}
                        // value={dateMomentObj !== "" ? dateMomentObj : ""}
                        ref={datetimeInputRef}
                        closeOnSelect
                        input={false}
                        // open={true}
                        onChange={(date) => {
                          handleDateTimeChange(date);
                        }}
                        // timeFormat={false}
                        onClose={() => setOpendate(false)}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <label htmlFor="duration_value" className="form-label">
                      Valid for*
                    </label>
                  </div>
                  <div className="" style={{ display: "flex" }}>
                    <div className="col-md-6" style={{ marginLeft: "-15px" }}>
                      <div className="mb-3">
                        <input
                          type="number"
                          name="duration_value"
                          id="duration_value"
                          // placeholder="Your first name, e.g. Walter"
                          className="form-control"
                          autoComplete="false"
                          value={banner.duration_value}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      {errors.duration_value && (
                        <span className="errorMessageText text-danger">
                          {errors.duration_value}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <select
                          name="duration_unit"
                          id="duration_unit"
                          className="form-control form-select"
                          onChange={(e) => handleChange(e)}
                          value={banner.duration_unit}
                        >
                          <option value="" key={0}>
                            Select
                          </option>
                          {duration_units?.map((item, idx) => {
                            return (
                              <>
                                <option key={idx + 1} value={`${item}`}>
                                  {item}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      {errors.duration_value && errors.duration_unit && (
                        <span className="errorMessageText text-danger">
                          {errors.duration_value}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="activityDetailsId" className="form-label">
                      Activity*
                    </label>
                    <Select
                      name="activityDetailsId"
                      id="activityDetailsId"
                      classNamePrefix="select"
                      options={activities.map((item, idx) => ({
                        value: item?._id,
                        label: item?.activityTitle,
                      }))}
                      value={banner.activityDetailsId.map((item) => {
                        let find = activities.find((it) => it._id === item);
                        return {
                          value: find?._id,
                          label: find?.activityTitle,
                        };
                      })}
                      isMulti
                      onChange={(val) => {
                        setbanner((prev) => {
                          return {
                            ...prev,
                            activityDetailsId: val.map((item) => item.value),
                          };
                        });
                      }}
                    />
                  </div>
                </div> */}
                {/* <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="offerType" className="form-label">
                      Offer Type*
                    </label>
                    <input
                      type="text"
                      name="offerType"
                      id="offerType"
                      // placeholder="Your first name, e.g. Walter"
                      className="form-control"
                      autoComplete="false"
                      value={banner.offerType}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.offerType && (
                    <span className="errorMessageText text-danger">
                      {errors.offerType}
                    </span>
                  )}
                </div> */}
                {/* offer Type */}
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="offerType" className="form-label">
                      Offer Type*
                    </label>
                    {/* <input
                      type="text"
                      name="offerType"
                      id="offerType"
                      // placeholder="Your first name, e.g. Walter"
                      className="form-control"
                      autoComplete="false"
                      value={banner.offerType}
                      onChange={(e) => handleChange(e)}
                    /> */}
                    <select
                      name="offerType"
                      id="offerType"
                      className="form-control form-select"
                      value={banner.offerType}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">Select Any offer</option>
                      <option value="limitedTimeOffer">
                        Limited-time offer
                      </option>
                      <option value="earlyBirdOffer">Early-bird offer</option>
                      <option value="lastMinuteOffer">Last-minute offer</option>
                    </select>
                  </div>
                  {banner.offerType === "limitedTimeOffer" ? (
                    <p>
                      Attract customers to activity dates and time slots you
                      struggle to fill. Eg: Monday mornings
                    </p>
                  ) : banner.offerType === "earlyBirdOffer" ? (
                    <p>
                      To attract bookings at least 30 days in advance Notice
                      period: Early-bird offer All bookings made at least (7)
                      days in advance will be eligible for this offer Add a box
                      to insert no of days whose minimum value will be 7and
                      maximum value can be within the valid no of days
                    </p>
                  ) : banner.offerType === "lastMinuteOffer" ? (
                    <p>
                      For activities happening very soon, e.g. in 3 days’ time
                      Notice period: Last-minute offer For any unsold spots up
                      to (3) days before the activity Add a box to insert no of
                      days whose maximum value will be 3.
                    </p>
                  ) : (
                    ""
                  )}
                  {banner.offerType === "limitedTimeOffer" ? (
                    <div>
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <div className="">
                          <div className="d-flex justify-content-start">
                            <div className="limitedStartDate">
                              <label htmlFor="" className="form-label">
                                Start Date
                              </label>
                              <br />
                              <div className="dateinputborder">
                                <input
                                  type="date"
                                  name="limitedStartDate"
                                  id="limitedStartDate"
                                  min={nextDay}
                                  value={banner.limitedStartDate}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                            </div>
                            <div className="limitedStartDate">
                              <label htmlFor="" className="form-label">
                                End Date
                              </label>
                              <br />
                              <div className="dateinputborder">
                                {" "}
                                <input
                                  type="date"
                                  name="limitedEndDate"
                                  id="limitedEndDate"
                                  min={banner.limitedStartDate}
                                  value={banner.limitedEndDate}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label htmlFor="">Select Time</label>
                            <TimePicker
                              clockIcon={null}
                              clearIcon={null}
                              className="form-control"
                              hourPlaceholder="hh"
                              minutePlaceholder="mm"
                              // value={times.times}
                              value={banner.limitedOfTime}
                              minTime=""
                              format="hh:mm a"
                              openClockOnFocus={false}
                              onChange={(time) => {
                                let formattedTime = formatTimeToAMPM(time);

                                // settimes(formattedTime);

                                setbanner((prevBanner) => ({
                                  ...prevBanner,
                                  limitedOfTime: formattedTime, // Set limitedOfTime based on the selected time from the TimePicker
                                }));
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : banner.offerType === "earlyBirdOffer" ? (
                    <div>
                      <label htmlFor="" className="form-label">
                        No Of Days
                      </label>
                      <span
                        style={{
                          backgroundColor: "#d55959",
                          color: "white",
                          marginBottom: "2px",
                          display:
                            Number(banner?.earlyNoOfDays) === "" ||
                            Number(banner?.earlyNoOfDays) < 7 ||
                            Number(banner?.earlyNoOfDays) > 30
                              ? "block"
                              : "none",
                        }}
                      >
                        Please Enter between 7 to 30
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        name="earlyNoOfDays"
                        id="earlyNoOfDays"
                        // max={Number(daysInMonth)}
                        onChange={(e) => {
                          let value = e.target.value;

                          // Allow users to clear the input

                          if (value.length <= 2 && value <= 30) {
                            setbanner((prevBanner) => ({
                              ...prevBanner,
                              earlyNoOfDays: value,
                            }));
                          }
                        }}
                        // onChange={(e) => handleChange(e)}
                        value={banner.earlyNoOfDays}
                      />
                    </div>
                  ) : banner.offerType === "lastMinuteOffer" ? (
                    <div>
                      <label htmlFor="" className="form-label">
                        Date Or Time
                      </label>
                      <div className="" style={{ display: "flex" }}>
                        <div
                          className="col-md-6"
                          style={{ marginLeft: "-15px" }}
                        >
                          <div className="mb-3">
                            <span
                              style={{
                                backgroundColor: "#d55959",
                                color: "white",
                                marginBottom: "2px",
                                display:
                                  banner.lastMinuteValue > 3 &&
                                  banner.lastMinuteUnit === "days"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              You can select Maximum 3 Days
                            </span>
                            <input
                              type="number"
                              name="lastMinuteValue"
                              id="lastMinuteValue"
                              placeholder="Enter date or time"
                              className="form-control"
                              autoComplete="false"
                              value={banner.lastMinuteValue}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                          {/* {errors.duration_value && (
                            <span className="errorMessageText text-danger">
                              {errors.duration_value}
                            </span>
                          )} */}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <select
                              name="lastMinuteUnit"
                              id="lastMinuteUnit"
                              className="form-control form-select"
                              onChange={(e) => handleChange(e)}
                              value={banner.lastMinuteUnit}
                            >
                              <option value="" key={0}>
                                Select
                              </option>
                              {duration_units?.map((item, idx) => {
                                return (
                                  <>
                                    <option key={idx + 1} value={`${item}`}>
                                      {item}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          {/* {errors.duration_value && errors.duration_unit && (
                            <span className="errorMessageText text-danger">
                              {errors.duration_value}
                            </span>
                          )} */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {errors.offerType && (
                    <span className="errorMessageText text-danger">
                      {errors.offerType}
                    </span>
                  )}
                </div>

                {/* Which time slots are included?{" "} */}
                <div className="row col-md-12">
                  <label htmlFor="" style={{ fontWeight: "bold" }}>
                    Which time slots are included?{" "}
                  </label>
                  <div className="d-flex">
                    <div className="col-md-6">
                      <label htmlFor="">Select Days</label>{" "}
                      <Select
                        name="daySlot"
                        id="daySlot"
                        classNamePrefix="select"
                        options={weekDaysOptions}
                        value={
                          banner.daySlot && Array.isArray(banner.daySlot)
                            ? banner.daySlot.map((item) => ({
                                value: item,
                                label: item,
                              }))
                            : []
                        }
                        isMulti
                        onChange={(selectedOptions) => {
                          const updatedValues = selectedOptions
                            ? selectedOptions.map((option) => option.value)
                            : [];
                          setbanner((prevBanner) => ({
                            ...prevBanner,
                            daySlot: updatedValues,
                          }));
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="" className="form-label">
                        Slot Start Date
                      </label>
                      <input
                        name="slotStartDate"
                        type="date"
                        className="form-control"
                        onChange={(e) => handleChange(e)}
                        value={banner.slotStartDate}
                      />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="" className="form-label">
                        Slot End Date
                      </label>
                      <input
                        name="slotEndDate"
                        type="date"
                        min={banner.slotStartDate}
                        className="form-control"
                        onChange={(e) => handleChange(e)}
                        value={banner.slotEndDate}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="discountPercentage" className="form-label">
                      Discount Percentage*
                    </label>
                    <input
                      type="number"
                      name="discountPercentage"
                      id="discountPercentage"
                      // placeholder="Your first name, e.g. Walter"
                      className="form-control"
                      autoComplete="false"
                      value={banner.discountPercentage}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.discountPercentage && (
                    <span className="errorMessageText text-danger">
                      {errors.discountPercentage}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    Offer Description
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    // placeholder="Your first name, e.g. Walter"
                    className="form-control"
                    autoComplete="false"
                    value={banner.description}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex m-2">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  navigate("/manage-special-offers");
                }}
              >
                Cancel
              </button>
              <button type="submit" className="mt-2 btn btn-primary  d-table">
                Edit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
  //   }
};

export default EditSpecialOffer;
