import React from 'react';

// Components
import { ToastContainer } from 'react-toastify';
import AppRoutes from './routes/AppRoutes';

import './App.css';
import '../src/assets/base.css';
import 'react-toastify/dist/ReactToastify.css';
import { closeModal } from './redux/merchantSlice/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
//import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  const dispatch = useDispatch()
  const isModalOpen = useSelector(state => state.modalSlice?.isModalOpen);
  const handleCloseModal = (e) => {
    if (isModalOpen) {
      dispatch(closeModal())
    }
  }
  return (
    <React.Fragment>
      <div className="App" onClick={(e) => { handleCloseModal(e) }} >
        <AppRoutes />
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App; //c
