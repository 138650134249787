// Hooks
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
//Modal
import CancelModal from "../../../Component/Modal/CancelModal";
// Utils
import { apiCallError } from "../../../utils/common-utils";
import { getOnlyFullDate } from "../../../utils/date-functions";

// Helpers
import { toast } from "react-toastify";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllActivities } from "../../../redux/adminSlice/activitySlice";
import { getAllBookings } from "../../../redux/merchantSlice/bookingSlice";

// Components
import DatePicker from "react-date-picker";
import Loader from "../../../Component/Loader";
import Table from "../../../Component/Table";
import Select from "react-select";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-time-picker/dist/TimePicker.css";
import "./styles.css";
import { BASE_URL } from "../../../constants/config";
import DownloadPdf from "../../../constants/DownloadPdf";

function ViewBookings() {

const urlString=useLocation()
const [isFromOrderInvoice, setisFromOrderInvoice] = useState(false);
useEffect(()=>{
  if(urlString.pathname==="/view-bookingInvoice"){
    setisFromOrderInvoice(true);
  }else{
    setisFromOrderInvoice(false)
  }
},[urlString.pathname])
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [activity, setactivity] = useState({
    activityId: "",
    activityTitle: "",
    startDate: "",
    endDate: "",
  });
  const [searchText, setsearchText] = useState("");
  const [isLoadings, setisLoading] = useState(false);
  const [cancelmodal, setCancelModal] = useState(false);
  const { activities } = useSelector((state) => state.adminActivityActionSlice);
  const { bookings, } = useSelector((state) => state.bookingSlice);
  const [bookingData, setBookingData] = useState([]);
  const [id, setId] = useState();
  const reset = () => {
    setactivity({
      activityId: "",
      activityTitle: "",
      startDate: "",
      endDate: "",
    });
  };
  // Component did mount
  useEffect(() => {
    dispatch(getAllActivities());
    dispatch(getAllBookings());
    
  }, []);


  // Component did update
  useEffect(() => {
    setisLoading(true);
    if (bookings?.length > 0) {
      setBookingData(bookings);
      // setcancelStatuses(bookings?.map(() => ({ cancelled: false })));
    }
    setisLoading(false);
  }, [bookings]);


  const handleStartDateChange = (date) => {
    setactivity({
      ...activity,
      startDate: getOnlyFullDate(date),
    });
  };
  const handleEndDateChange = (date) => {
    setactivity({
      ...activity,
      endDate: getOnlyFullDate(date),
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setisLoading(true);
      let res = await JSON_API["getAllBookings"](activity);
      if (res.isSuccess) {
        setisLoading(false);
        setBookingData(res?.data?.data);
        // setactivity("");
        reset();
        toast.success("Activity booking get success");
        // window.location.reload();
        // apiCallSuccess(res.data);
      } else {
        toast.error("Activity Booking get failed");
        setisLoading(false);
        apiCallError(res?.error?.message);
      }
    } catch (error) {
      setisLoading(false);
    }
  };
  const handleReset = (e) => {
    e.preventDefault();
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };
  const handleClick = (id) => {
    setCancelModal(true);
    setId(id);
  };

  const InvoiceDownload =(ele)=>{
   const urlstring= `${BASE_URL}api/v1/merchant/view-booking-ticket/${ele.orderId}/${ele.activity[0]._id}`;

     DownloadPdf(urlstring,'GET',{},setisLoading)
  }

  const PayoutDownload =(ele)=>{
   const urlstring= `${BASE_URL}api/v1/merchant/view-booking-payout/${ele.orderId}/${ele.activity[0]._id}`;

     DownloadPdf(urlstring,'GET',{},setisLoading)
  }

  // const cancelBooking = async (id) => {
  //   try {
  //     let res = await JSON_API["cancelBooking"]({ id });

  //     if (res.isSuccess) {
  //       dispatch(getAllBookings());
  //       toast.success("Booking successfully cancelled.");
  //     } else {
  //       toast.error(res?.error?.message);
  //     }
  //   } catch (error) {
  //   }
  // };

  const filterData = () => {
    if (searchText !== "") {
      return bookingData.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return bookingData;
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    //  if(isModalOpen) {
    // dispatch(closeModal())
    //  }
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 100,
        },
        {
          label: "Activity Name",
          field: "activity_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Booked By",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Booking ID",
          field: "booking_id",
          width: 100,
        },
        {
          label: "Cancel Booking",
          field: "cancel_booking",
        },
        {
          label: "View Details",
          field: "detail_view",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData()?.length > 0
          ? filterData().map((item, idx) => {
              return {
                sl: idx + 1,
                activity_name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, textAlign: "center" }}
                        >
                          {/* {item?.marchentFirstName?.charAt(0)?.toUpperCase() +
                            item?.marchentFirstName?.slice(1)?.toLowerCase() +
                            " " +
                            item?.marchentLastName?.charAt(0)?.toUpperCase() +
                            item?.marchentLastName?.slice(1)?.toLowerCase()} */}
                          {item?.activity[0]?.activityTitle}
                        </div>
                        {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                      </div>
                    </div>
                  </div>
                ),
                name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, textAlign: "center" }}
                        >
                          {item?.userDetails?.firstName
                            ?.charAt(0)
                            ?.toUpperCase() +
                            item?.userDetails?.firstName
                              ?.slice(1)
                              ?.toLowerCase() +
                            " " +
                            item?.userDetails?.lastName
                              ?.charAt(0)
                              ?.toUpperCase() +
                            item?.userDetails?.lastName
                              ?.slice(1)
                              ?.toLowerCase()}
                        </div>
                        {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                      </div>
                    </div>
                  </div>
                ),
                cancel_booking: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <button
                          disabled={
                            item?.bookingStatus === "cancelled" ? true : false
                          }
                          className={`btn ${
                            item?.bookingStatus === "confirmed"
                              ? "btn-primary"
                              : "btn-secondary"
                          }`}
                          style={{
                            width: "90px",
                            backgroundColor:
                              item?.bookingStatus === "confirmed"
                                ? "green"
                                : "red",
                          }}
                          onClick={() => handleClick(item?._id)}
                          // onClick={() => cancelBooking(item?._id)}
                        >
                          {item?.bookingStatus === "confirmed"
                            ? "CANCEL"
                            : "CANCELLED"}
                        </button>
                      </div>
                    </div>
                  </div>
                ),
                booking_id: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, textAlign: "center" }}
                        >
                          {item?.alpfaNueID}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                // status: (
                //   <button
                //     className={`badge ${
                //       item?.status ? "bg-success" : "bg-danger"
                //     } border-0`}
                //   >
                //     {item?.status ? "Active" : "Deactivated"}
                //   </button>
                // ),
                detail_view: (
                  <div>
                  
                   {
                    
                    (isFromOrderInvoice)?<>
                    <button className="downloadInvoice" onClick={()=>{
                      InvoiceDownload(item)
                    }} >Download ticket</button>  <button className="downloadInvoice" onClick={()=>{
                      PayoutDownload(item)
                    }} >Download Invoice</button></>:
                    <Link to={`/booking-details/${item?._id}`} state={item}>
                    <button className="me-2 btn-icon btn-icon-only btn btn-outline-primary">
                      <i className="fa fa-info-circle" aria-hidden="true"></i>
                    </button>
                  </Link>
                   }
                 
                  </div>
                ),
              };
            })
          : [],
    };
  }, [bookingData, searchText,isFromOrderInvoice])

  return (
    <>
      {isLoadings && <Loader />}
      <form
        className=""
        onSubmit={(e) => handleSubmit(e)}
        onClick={(e) => handleCloseModal(e)}
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Activity Bookings</div>
              <div className="row" style={{ padding: "1rem" }}>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      htmlFor="activityId"
                      className="form-label font-weight-bold"
                    >
                      Activity*
                    </label>
                    <Select
                      name="activityId"
                      id="activityId"
                      classNamePrefix="select"
                      ref={inputRef}
                      options={activities.map((item, idx) => ({
                        value: item?._id,
                        label: item?.activityTitle,
                      }))}
                      onChange={(val) => {
                        setactivity((prev) => {
                          return {
                            ...prev,
                            activityId: val?.value,
                            activityTitle: val?.label,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="tourDate"
                    >
                      Start Date
                    </label>
                    <DatePicker
                      id="tourDate"
                      inputRef={inputRef}
                      calendarIcon={null}
                      className="form-control"
                      clearIcon={null}
                      value={activity.startDate}
                      format="y-MM-dd"
                      // closeCalendar  // on 'select'
                      shouldCloseCalendar={({ reason }) =>
                        reason === "select" || reason === "outsideAction"
                      }
                      onChange={handleStartDateChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="tourDate"
                    >
                      End Date
                    </label>
                    <DatePicker
                      id="tourDate"
                      inputRef={inputRef}
                      calendarIcon={null}
                      className="form-control"
                      clearIcon={null}
                      value={activity.endDate}
                      format="y-MM-dd"
                      // closeCalendar  // on 'select'
                      shouldCloseCalendar={({ reason }) =>
                        reason === "select" || reason === "outsideAction"
                      }
                      onChange={handleEndDateChange}
                    />
                  </div>
                </div>
              </div>

              <div className="buttons">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="mt-2 btn btn-link d-table"
                    onClick={handleReset}
                    style={{ marginRight: "10px" }}
                  >
                    Reset
                  </button>
                  <button
                    className="mt-2 btn btn-primary d-table"
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Table
        striped
        tableTitle="All Booking Details"
        data={tableData}
        onSearch={(val) => setsearchText(val.target.value)}
        tabflag={true}
      />
      {cancelmodal && <CancelModal Closemodal={setCancelModal} id={id} />}
    </>
  );
}

export default ViewBookings;
