import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { JSON_API } from "../../services/api/jsonApiCallWithInterceptor";
import { toast } from "react-toastify";
import { getAllBookings } from "../../redux/merchantSlice/bookingSlice";

export default function CancelModal({ Closemodal, id }) {
  const [cancelReason, setCancelReason] = useState("");
  const [merchantRequestDescription, setMerchantRequestDescription] =
    useState("");
  const [otherRequestDescription, setOtherRequestDescription] = useState("");

  const dispatch = useDispatch();

  const handleCheckboxChange = (reason) => {
    if (cancelReason === reason) {
      setCancelReason(null);
    } else {
      setCancelReason(reason);
    }
   
  };



  const changeHandler = (e) => {
    const { value } = e.target;
    if (cancelReason === "merchantRequest") {
      setMerchantRequestDescription(value);
    } else if (cancelReason === "otherRequest") {
      setOtherRequestDescription(value);
    }
  
  };

  const handleSubmit = async () => {
    if (cancelReason === "") {
      toast.error("Select Any Reason For Cancel The Booking");
      return;
    }
    if (
      (cancelReason === "merchantRequest" && merchantRequestDescription === "") ||
      (cancelReason === "otherRequest" && otherRequestDescription === "")
    ) {
      toast.error("Describe What You Want to Cancel The Booking");
      return;
    }
    // (otherRequestDescription && otherRequestDescription === "")
    try {
      let res = await JSON_API["cancelBooking"]({
        id,
        reason: cancelReason,
        description: merchantRequestDescription
          ? merchantRequestDescription
          : otherRequestDescription
          ? otherRequestDescription
          : "",
      });

      if (res.isSuccess) {
        dispatch(getAllBookings());
        toast.success("Booking successfully cancelled.");
        Closemodal();
      } else {
        toast.error(res?.error?.message);
      }
    } catch (error) {
    }
  };

  return (
    <>
      <div className="cancelmodal">
        <div className="cancelmodalcntnt">
          <div className="closemark" onClick={() => Closemodal()}>
            <i className="fa-solid fa-xmark"></i>
          </div>
          <div className="cntnt">
            <h5
              style={{
                fontSize: "18px",
                fontWeight: "600",
                marginBottom: "20px",
              }}
            >
              Are you sure you want to cancel this booking? If yes, please
              select a valid reason
            </h5>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div>
                <label htmlFor="customerCheckbox" className="ml-2">
                  <input
                    type="checkbox"
                    id="customerCheckbox"
                    className="mr-1"
                    value={"customerRequest"}
                    checked={cancelReason === "customerRequest"}
                    onChange={() => handleCheckboxChange("customerRequest")}
                  />
                  Customer Request
                </label>
                <br />

                <label htmlFor="merchantCheckbox" className="ml-2">
                  <input
                    type="checkbox"
                    id="merchantCheckbox"
                    className="mr-1"
                    value={"merchantRequest"}
                    checked={cancelReason === "merchantRequest"}
                    onChange={() => handleCheckboxChange("merchantRequest")}
                  />
                  Merchant Request
                </label>
                <br />
                {cancelReason === "merchantRequest" && (
                  <textarea
                    className="form-control mb-4"
                    id=""
                    rows={2}
                    value={merchantRequestDescription}
                    placeholder="Enter Valid Reason"
                    style={{ boxShadow: "none" }}
                    onChange={(e) => changeHandler(e)}
                  />
                )}

                <label htmlFor="otherCheckbox" className="ml-2">
                  <input
                    type="checkbox"
                    id="otherCheckbox"
                    className="mr-1"
                    value={"otherRequest"}
                    checked={cancelReason === "otherRequest"}
                    onChange={() => handleCheckboxChange("otherRequest")}
                  />
                  Other Request
                </label>
                <br />

                {cancelReason === "otherRequest" && (
                  <textarea
                    className="form-control mb-4"
                    id=""
                    rows={2}
                    value={otherRequestDescription}
                    placeholder="Enter Valid Reason"
                    style={{ boxShadow: "none" }}
                    onChange={(e) => changeHandler(e)}
                  />
                )}
              </div>

              <div className="subBtnDiv">
                <button type="submit" className="subBtn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>

          {/* <div>
            <label>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              Checkbox Example
            </label>
            <p>Checkbox is {isChecked ? "checked" : "unchecked"}</p>
          </div> */}
        </div>
      </div>
    </>
  );
}
